import React from 'react';

import './elementos/estilos.css';
import logo from './images/logo.png';

function App() {
  return (
    <div className="areaMain">
      
      <div className="areaVideo">
        
        <div className="centerElement">
          <div id="logos">
            <img className="logo" src={logo} alt="Logo "/>
          </div>
          <div className="clear"></div>
          <div id="vid">
            <iframe src="https://vimeo.com/event/1494488/embed" frameBorder="0" allow="autoplay; picture-in-picture; fullscreen" allowFullScreen></iframe>
          </div>
        </div>
      </div>

      <div className="areaChat">
          <iframe src="https://vimeo.com/event/1494488/chat/" width="100%" height="100%" frameborder="0"></iframe>
      </div>
      
   </div>
  );
}

export default App;
